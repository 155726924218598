//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductSwiper from '@/frontend/components/common/product-swiper.vue';
import {mapActions, mapGetters} from 'vuex';
import {adminCodes} from '@/enums/adminSettings'

export default {
  name: 'home',
  components: {ProductSwiper},
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      slide: {
        imageUrl: '',
        text: '',
      },
      interval: null,
      slideIndex: 0,
      bestSellingProducts: undefined,
      newProducts: undefined,
      productCatalogs: [],
    }
  },
  computed: {
    ...mapGetters('backoffice/settings', ['adminSettingByCode']),
    carouselItems() {
      if (this.section.slider) {
        return this.section.slider.map((item) => {
          return {
            src: item.image.fieldValue.fullFileUrl,
            text: item.text.fieldValue.value
          }
        })
      } else {
        return []
      }
    },
    visibleButton() {
      return this.$vuetify.breakpoint.smAndDown ? 'visible' : '';
    },
    getProductNumber(){
        const productNumber = this.adminSettingByCode(
          adminCodes.ADM_CP_FeaturedProductNumber
        )
      return Number(productNumber.value);
    }
  },
  async mounted() {
    this.setUpSlide(this.slideIndex)
    this.interval = setInterval(this.changeImg, 5000)
    this.$on('hook:beforeDestroy', () => clearInterval(this.interval))
    await this.getBestSellingProducts()
    await this.getProductCategories()
    this.evenOutCategoryCount()
    await this.getNewProducts()
  },
  methods: {
    ...mapActions('backoffice/products', ['fetchRetailProducts']),
    getNewProducts() {
      this.fetchRetailProducts({
        filters: {
          pageSize: this.getProductNumber,
          pageNo: 1,
        },
      }).then((response) => {
        this.newProducts = response.map((item) => {
          return {
            name: item.name,
            image: item.imageUrl,
            link: `/product-detail/${item.code}`,
          }
        })
      }).catch(() => {
        this.$toast.error("Error while fetching new products")
      })
    },
    getProductCategories(){
      return this.$axios.$get('ProductCategories?getOnlyParent=true').then((response) => {
        this.productCatalogs = response.map((item) => {
          return {
            name: item.name,
            image: item.imageURL,
            code: item.code,
          }
        })
          .slice(0, 4)
      }).catch(() => {
        this.$toast.error("Error while fetching product categories")
      })
    },
    evenOutCategoryCount() {
      if(this.productCatalogs && this.productCatalogs.length > 1 && this.productCatalogs.length % 2!== 0 ){
        const [firstEl] = this.productCatalogs
        this.productCatalogs.push({
          name: firstEl.name,
          image: firstEl.image,
          code: firstEl.code,
        })
      }
    },
    getBestSellingProducts(){
      this.fetchRetailProducts({
        filters: {
          pageSize: this.getProductNumber,
          pageNo: 1,
          featuredOnPortal: true,
        },
      }).then((response) => {
        this.bestSellingProducts = response.map((item) => {
          return {
            name: item.name,
            image: item.imageUrl,
            link: `/product-detail/${item.code}`,
          }
        })
      }).catch(() => {
        this.$toast.error("Error while fetching best selling products")
      })
    },
    changeImg() {
      if (this.section.slider.length -1 === this.slideIndex) {
        this.slideIndex = 0
        this.setUpSlide(this.slideIndex)
        return;
      }
      this.slideIndex++
      this.setUpSlide(this.slideIndex)
    },
    setUpSlide(index) {
      if (this.section.slider[index]) {
        this.slide.imageUrl = this.section.slider[index].image.fieldValue.fullFileUrl
        this.slide.text = this.section.slider[index].text.fieldValue.value
      }
    },
    navigateToPage(id) {
      const idWithoutSpaces = id.replace(/\s/g, '')
      this.$router.push({path: 'services', query: {
          scrollTo: idWithoutSpaces
        }})
    },
    navigateToProductCatalog(code) {
      this.$router.push(`/products/${code}`)
    },
  },
}
